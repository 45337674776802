import { ComponentType } from '@angular/cdk/portal';
import { inject, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { SharedUiSnackbarComponent, SnackbarData } from '@enx/shared/ui/components';

@Injectable()
export class SharedFeatureSnackbarService {
  readonly snackbar = inject(MatSnackBar);

  openSuccessSnackbar(messages: string[]): MatSnackBarRef<SharedUiSnackbarComponent> {
    return this.openSnackbarFromComponent<SharedUiSnackbarComponent, SnackbarData>(SharedUiSnackbarComponent, {
      panelClass: ['enx-snackbar--success'],
      data: { messages, type: 'success' },
    });
  }

  openErrorSnackbar(messages: string[]): MatSnackBarRef<SharedUiSnackbarComponent> {
    return this.openSnackbarFromComponent<SharedUiSnackbarComponent, SnackbarData>(SharedUiSnackbarComponent, {
      panelClass: ['enx-snackbar--error'],
      data: { messages, type: 'error' },
    });
  }
  openHtmlErrorSnackbar(message: string): MatSnackBarRef<SharedUiSnackbarComponent> {
    const snackbarData: SnackbarData = {
      messages: [message],
      type: 'error',
      isHtml: true,
    };

    return this.openSnackbarFromComponent<SharedUiSnackbarComponent, SnackbarData>(SharedUiSnackbarComponent, {
      panelClass: ['enx-snackbar--error'],
      data: snackbarData,
    });
  }

  openWarningSnackbar(messages: string[]): MatSnackBarRef<SharedUiSnackbarComponent> {
    return this.openSnackbarFromComponent<SharedUiSnackbarComponent, SnackbarData>(SharedUiSnackbarComponent, {
      panelClass: ['enx-snackbar--warning'],
      data: { messages, type: 'warning' },
    });
  }

  openSnackbarFromComponent<T, D>(component: ComponentType<T>, config?: MatSnackBarConfig<D>): MatSnackBarRef<T> {
    const panelClass = ['enx-snackbar', ...(config?.panelClass || [])];

    return this.snackbar.openFromComponent<T, D>(component, {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      ...config,
      panelClass,
    });
  }
}
